import React, { useEffect } from 'react';
import Navbar from '../../components/Navbar/index.js';
import Footer from '../../components/Footer/index.js';
import WhatsApp from '../../Assets/whatsapp.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import './style.css';
import Video from '../../Assets/video2.mp4';
import Foto from '../../Assets/Lara.png';
import logoFundo from '../../Assets/logo-nutri.png'


function Lara() {
  
  return (
    <>
    <Navbar />
   <header className='video-nutricionistas'>
        <video src={Video} type="video/mp4" className="video-container" loop autoPlay playsinline muted></video>
        <div className='content-mobile'>
        <img src={Foto} className='foto-mobile'></img> 
        <div className='info-mobile'>
          <h1 className='name-mobile'>Lara Vitory</h1>
          <p className='job-mobile'>Nutricionista</p>
        </div>
      </div>
   </header>
   <div className='content'>
    <img src={Foto} className='foto'></img> 
    <div className='info'>
      <h1 className='name'>Lara Vitory</h1>
      <p className='job'>Nutricionista</p>
    </div>
   </div>
   <section className='content-nutri'>
    <img src={logoFundo} className='logo-fundo'></img>
    <div content='content-info'>
        <h1 className='title'>Sobre</h1>
        <p className='description'>É com grande satisfação que apresentamos Lara Vitory, 
        nutricionista fora da curva aqui da Clínica Bridges. Lara é uma especialista em emagrecimento 
        e nutrição esportiva, e o seu compromisso e paixão é pela saúde e bem-estar de nossos pacientes.
        Lara Vitory se destaca como uma profissional que compreende as necessidades únicas de cada indivíduo. 
        Sua abordagem personalizada e orientada para resultados tem ajudado inúmeras pessoas a alcançarem seus 
        objetivos de emagrecimento, melhorar seu desempenho esportivo e adotar hábitos alimentares mais saudáveis.</p>
        <h2 className='crn'>CRN: 16229</h2>
      </div>
   </section>
    
    <Footer></Footer>
    </>
  );
}

export default Lara;
