import React, { useEffect } from 'react';
import Navbar from '../../components/Navbar/index.js';
import Footer from '../../components/Footer/index.js';
import WhatsApp from '../../Assets/whatsapp.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import './style.css';
import Video from '../../Assets/video2.mp4';
import Foto from '../../Assets/Jessica.png';
import logoFundo from '../../Assets/logo-nutri.png'


function Jessica() {
  
  return (
    <>
    <Navbar />
   <header className='video-nutricionistas'>
        <video src={Video} type="video/mp4" className="video-container" loop autoPlay playsinline muted></video>
        <div className='content-mobile'>
        <img src={Foto} className='foto-mobile'></img> 
        <div className='info-mobile'>
          <h1 className='name-mobile'>Jessica Lima</h1>
          <p className='job-mobile'>Nutricionista</p>
        </div>
      </div>
   </header>
   <div className='content'>
    <img src={Foto} className='foto'></img> 
    <div className='info'>
      <h1 className='name'>Jessica Lima</h1>
      <p className='job'>Nutricionista</p>
    </div>
   </div>
   <section className='content-nutri'>
    <img src={logoFundo} className='logo-fundo'></img>
    <div content='content-info'>
        <h1 className='title'>Sobre</h1>
        <p className='description'>Jessica Lima é uma profissional apaixonada e dedicada que integra a 
        equipe de nutricionistas da Clínica Bridges. Com um compromisso inabalável com a promoção da saúde
         e o bem-estar através da nutrição, Jéssica ama esportes e pratíca o que prega quando o assunto é ter 
         uma vida saudável.
        Com anos de experiência prática, Jessica tem trabalhado com uma variedade de pacientes, desde aqueles que
        buscam melhorar sua composição corporal até atletas que buscam maximizar seu desempenho. Sua abordagem 
        considera não apenas os aspectos nutricionais, mas também as preferências individuais, estilo de vida e metas
          específicas de cada pessoa.
        </p>
        <h2 className='crn'>CRN: 12824</h2>
      </div>
   </section>
    
    <Footer></Footer>
    </>
  );
}

export default Jessica;
