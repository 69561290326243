import React, { useEffect, Link } from 'react';
import Navbar from '../components/Navbar/index.js';
import Form from '../components/Form/index.js';
import LogoFundo from '../Assets/logo\ fundo.png';
import FundoContato from '../Assets/fundocontato.png';
import Emagrecimento from '../Assets/weight-loss.png';
import Nutrição from '../Assets/running.png';
import Estetica from '../Assets/intestine.png';
import Dieta from '../Assets/healthy-food.png';
import Clinica from '../Assets/clinic-history.png';
import Muscle from '../Assets/muscles.png';
import Accordion from '../components/Accordion/index.js'
import Footer from '../components/Footer/index.js';
import Alert from '../components/Alert/index.js';
import Blog from '../components/Blog/index.js';
import WhatsApp from '../Assets/whatsapp.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import './style.css';
import Video from '../Assets/video2.mp4';
import ScrollReveal from 'scrollreveal';
import Carousel from '../components/Nutricionistas/index.js';
import NaMidia from '../components/NaMidia/index.js';
import Exames from '../components/Exams/index.js';


function App() {
  useEffect(() => {
    window.sr = ScrollReveal({ reset: true });

    ScrollReveal().reveal('h1');
    ScrollReveal().reveal('h2', { delay: 100 });
    ScrollReveal().reveal('p', { delay: 100 });
    ScrollReveal().reveal('.img-especialidades', { delay: 100 });
  }, []);
  return (
    <>
    <Navbar />
    <section id='inicio'>
      <a href='https://wa.me/5585996444098'><img className="whats" src={WhatsApp}></img></a>
      <div className='left'>
        <video src={Video} type="video/mp4" className="video" loop autoPlay playsinline muted></video>
      </div>
      <div className='right'>
        <h1>Nutrição inteligente é a ponte para uma vida saudável.</h1>
        <div>
          <h2>Venha evoluir com a Bridges</h2>
          <a href='https://wa.me/5585996444098'><WhatsAppIcon/>Fale conosco</a>
        </div>
      </div>
    </section>
    <section>
      <div id='institucional' className='area-1'>
        <h1>Seja bem-vindo a Clínica Bridges</h1>
        <p>A Bridges nasceu apartir do compromisso em estabelecer uma ponte sólida em direção a uma vida saudável e vibrante. Somos uma clínica de nutrição e nutrologia, projetada para atender às necessidades individuais de nossos pacientes e capacitá-los a alcançar seus objetivos de saúde.
          Na Clínica Bridges, acreditamos que cada indivíduo é único, por esse motivo adotamos uma abordagem personalizada para direcionar nossos pacientes a atingir seus objetivos de forma saudável e sem sofrimento, através de hábitos alimentares inteligêntes.
          </p>
      </div>
      <div className='area-2'>
      </div>
    </section>
    <div className='background'></div>
    <section id='especialidades' className='section-especialidades'>
      <h1>Atendimento personalizado</h1>
      <div className='especialidades'>
        <div>
          <img className="img-especialidades" src={Emagrecimento}></img>
          <h2>Emagrecimento</h2>
          <p>Estratégias para controlar os gatilhos que impactam o ganho de peso para alcançar e manter um peso saudável.</p>
        </div>
        <div>
          <img className="img-especialidades" src={Nutrição}></img>
          <h2>Esporte de Alta Performance</h2>
          <p>Plano alimentar personalizado para otimizar o desempenho de atletas e alcançar melhores resultados</p>
        </div>
        <div>
          <img className="img-especialidades" src={Estetica}></img>
          <h2>Alterações Gastrointestinais</h2>
          <p>Aliviar sintomas relacionados a distúrbios digestivos, como: má digestão, prisão de ventre, síndrome do intestino irritável, azia e outros.</p>
        </div>
      </div>
      <div className='especialidades'>
        <div>
          <img className="img-especialidades" src={Clinica}></img>
          <h2>Anamnese Completa</h2>
          <p>A anamnese completa é a coleta de informações sobre o histórico médico, estilo de vida, sintomas e queixas do um paciente.</p>
        </div>
        <div>
          <img className="img-especialidades" src={Dieta}></img>
          <h2>Plano Alimentar</h2>
          <p>Orientações que visam atender às necessidades nutricionais de acordo com seus objetivos e restrições alimentares.</p>
        </div>
        <div>
          <img className="img-especialidades" src={Muscle}></img>
          <h2>Hipertrofia Muscular</h2>
          <p>Uma dieta adequada fornece os nutrientes necessários para a recuperação, crescimento das fibras e apoia o desempenho durante os treinos.</p>
        </div>
      </div>
    </section>
    <section id='Nutri' className='Nutri'>
      <h1>Nutricionistas</h1>
      <Carousel />
    </section>
    <div className='background2'></div>
    <NaMidia></NaMidia>
    <Exames />
    <Footer></Footer>
    </>
  );
}

export default App;
