import './style.css';
import EastIcon from '@mui/icons-material/East';

const NaMidia = () => {
  const midias = [
    {
      id: 1,
      link: 'https://diariodonordeste.verdesmares.com.br/opiniao/colunistas/nayana-siebra/dor-de-cabeca-caimbras-e-fadiga-confira-esses-e-outros-4-sinais-de-desidratacao-1.3260138',
      imagem: 'https://diariodonordeste.verdesmares.com.br/image/contentid/policy:1.3260160:1658854525/shutterstock_260282993_Easy-Resize.com.jpg?f=16x9&h=698&w=1280&$p$f$h$w=bb159ab',
      titulo: 'Retenção Hídrica',
      mensagem: 'Dor de cabeça, câimbras e fadiga: confira esses e outros 4 sinais de desidratação.'
    },
    {
      id: 2,
      link: 'https://diariodonordeste.verdesmares.com.br/opiniao/colunistas/nayana-siebra/alimentos-para-ganhar-massa-muscular-veja-o-que-comer-e-para-quem-a-dieta-e-indicada-1.3210398',
      imagem: 'https://diariodonordeste.verdesmares.com.br/image/contentid/policy:7.4621670:1648578334/shutterstock_1016717500_Easy-Resize.com.jpg?f=16x9&h=698&w=1280&$p$f$h$w=d5da547',
      titulo: 'Hipertrofia',
      mensagem: 'Alimentos para ganhar massa muscular? Veja o que comer e para quem a dieta é indicada.'
    },
    {
      id: 3,
      link: 'https://diariodonordeste.verdesmares.com.br/ser-saude/chocolate-conheca-os-beneficios-e-as-propriedades-1.3237861',
      imagem: 'https://diariodonordeste.verdesmares.com.br/image/contentid/policy:1.3237902:1654035178/WhatsApp%20Image%202022-05-31%20at%2019.08.19%20(1).jpeg?f=16x9&h=698&w=1280&$p$f$h$w=3d354eb',
      titulo: 'Chocolate',
      mensagem: 'Chocolate: conheça os benefícios e as propriedades.'
    }
  ];

  return (
    <section id="midia" className="section-midia">
      <div className="content-1">
        <h2 className="title-midia">Na <strong>Mídia</strong></h2>
      </div>
      <div className="content-2">
        <ul className="box-midia">
          {midias.map(reportagem => (
            <li key={reportagem.id}>
              <div className="img-box">
                <a target="_blank" rel="noopener noreferrer" href={reportagem.link}>
                  <img src={reportagem.imagem} alt={reportagem.titulo} />
                </a>
              </div>
              <div className="box-content-report">
                <a target="_blank" rel="noopener noreferrer" href={reportagem.link}>
                  <h3 className="title-reportagem">{reportagem.titulo}</h3>
                </a>
                <p className="msg-reportagem">{reportagem.mensagem}</p>
                <a target="_blank" rel="noopener noreferrer" href={reportagem.link} className="btn-site">
                  Veja a matéria <EastIcon />
                </a>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default NaMidia;
