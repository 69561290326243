import { useState } from 'react';
import './style.css';
import emailjs from '@emailjs/browser';

function Form() {
  const [name, setName] = useState('')
  const [number, setNumber] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

    function SendEmail(e){
        e.preventDefault();

        const templateParams = {
            from_name: name,
            number: number,
            message: message,
            email: email
          }
        
        
          emailjs.send("service_gedegfe", "template_3glh6w5", templateParams, "w1QBuIMhngdm1EymH")
          .then((response) => {
            console.log("Email enviado", response.status, response.text)
            setName('')
            setNumber('')
            setEmail('')
            setMessage('')
            
        
          }, (err) => {
            console.log("Erro: ", err)
          })

    }



  return (
    <div className="container">
      <form className="form" onSubmit={SendEmail}>
        <div className='numero'>
            <input 
            className="input"
            type="text"
            placeholder="Nome"
            onChange={(e) => setName(e.target.value)}
            value={name}
            />

             <input 
            className="input"
            type="text"
            placeholder="Número"
            onChange={(e) => setNumber(e.target.value)}
            value={number}
            />
            
        </div>
        <input 
            className="input"
            type="text"
            placeholder="E-mail"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
        />

        <textarea 
          className="textarea"
          placeholder="Assunto"
          onChange={(e) => setMessage(e.target.value)}
          value={message}
        />

        <input className="button" type="submit" value="Enviar" />
      </form>

    </div>
  );
}

export default Form;