import React, {Link} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Perfil from '../../Assets/perfil.png';
import Lara from '../../Assets/Lara.png';
import Ivo from '../../Assets/Ivo.png';
import Jessica from '../../Assets/Jessica.png';
import Leo from '../../Assets/Leo.png';
import Joao from '../../Assets/Joao.jpeg';
import './style.css';

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2, // Quantidade de slides visíveis
    slidesToScroll: 1, // Quantidade de slides a rolar
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
      <div>
        <a href="/Leo">
          <img src={Leo} alt="Leo Pontes" />
        </a>
          <h3>Leo Pontes</h3>
          <h4>Nutricionista</h4>
        </div>
        <div>
        <a href="/Joao">
          <img src={Joao} alt="João Matheus Cavalcanti" />
        </a>
          <h3>João Matheus Cavalcanti</h3>
          <h4>Nutricionista</h4>
        </div>
        <div>
          <a href='/Lara'>
            <img src={Lara} alt="Lara Castelo" />
          </a>
          <h3>Lara Vitóry Castelo</h3>
          <h4>Nutricionista</h4>
        </div>
        <div>
          <a href='/Ivo'>
            <img src={Ivo} alt="Ivo Rocha" />
          </a>
          <h3>Ivo Rocha</h3>
          <h4>Nutrólogo</h4>
        </div>
        <div>
          <a href='/Jessica'>
            <img src={Jessica} alt="Jéssica Lima" />
          </a>
          <h3>Jéssica Lima</h3>
          <h4>Nutricionista</h4>
        </div>
        
      </Slider>
    </div>
  );
};

export default Carousel;
