import React, { useEffect } from 'react';
import Navbar from '../../components/Navbar/index.js';
import Footer from '../../components/Footer/index.js';
import WhatsApp from '../../Assets/whatsapp.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import './style.css';
import Video from '../../Assets/video2.mp4';
import Foto from '../../Assets/Leo.png';
import logoFundo from '../../Assets/logo-nutri.png'


function Leo() {
  
  return (
    <>
    <Navbar />
   <header className='video-nutricionistas'>
        <video src={Video} type="video/mp4" className="video-container" loop autoPlay playsinline muted></video>
        <div className='content-mobile'>
        <img src={Foto} className='foto-mobile'></img> 
        <div className='info-mobile'>
          <h1 className='name-mobile'>Léo Pontes</h1>
          <p className='job-mobile'>Nutricionista</p>
        </div>
      </div>
   </header>
   <div className='content'>
    <img src={Foto} className='foto'></img> 
    <div className='info'>
      <h1 className='name'>Léo Pontes</h1>
      <p className='job'>Nutricionista</p>
    </div>
   </div>
   <section className='content-nutri'>
    <img src={logoFundo} className='logo-fundo'></img>
    <div content='content-info'>
        <h1 className='title'>Sobre</h1>
        <p className='description'>Léo Pontes é um renomado nutricionista e idealizador da Clínica Bridges, Sua paixão por ajudar as pessoas a alcançarem seus objetivos de saúde o impulsionou a criar a inovadora Clínica Bridges.
        A Clínica Bridges, é mais do que um centro de Nutrição e Nutrologia, é um ambiente acolhedor e inspirador, projetado com equipamentos de alta tecnologia e profissionais altamente capacitados para criar uma ponte entre os desafios de saúde de seus pacientes e um futuro mais saudável.
        A equipe de Nutrologia e Nutrição da Clínica Bridges trabalha em estreita colaboração com os pacientes, personalizando planos alimentares e oferecendo suporte contínuo.
        Saiba mais sobre a Clínica Bridges, a sua nova casa que vai cuidar da sua saúde.</p>
        <h2 className='crn'>CRN: 11712</h2>
      </div>
   </section>
    
    <Footer></Footer>
    </>
  );
}

export default Leo;
