import React, { useEffect } from 'react';
import Navbar from '../../components/Navbar/index.js';
import Footer from '../../components/Footer/index.js';
import WhatsApp from '../../Assets/whatsapp.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import './style.css';
import Video from '../../Assets/video2.mp4';
import Foto from '../../Assets/Ivo.png';
import logoFundo from '../../Assets/logo-nutri.png'


function Ivo() {
  
  return (
    <>
    <Navbar />
   <header className='video-nutricionistas'>
        <video src={Video} type="video/mp4" className="video-container" loop autoPlay playsinline muted></video>
        <div className='content-mobile'>
        <img src={Foto} className='foto-mobile'></img> 
        <div className='info-mobile'>
          <h1 className='name-mobile'>Ivo Rocha</h1>
          <p className='job-mobile'>Nutrólogo</p>
        </div>
      </div>
   </header>
   <div className='content'>
    <img src={Foto} className='foto'></img> 
    <div className='info'>
      <h1 className='name'>Ivo Rocha</h1>
      <p className='job'>Nutrólogo</p>
    </div>
   </div>
   <section className='content-nutri'>
    <img src={logoFundo} className='logo-fundo'></img>
    <div content='content-info'>
        <h1 className='title'>Sobre</h1>
        <p className='description'>O Nutrólogo Ivo Rocha é um profissional renomado e respeitado na área 
        da nutrologia esportiva, ele se destaca como especialista em otimização de desempenho para atletas
         de elite e entusiastas do esporte.
        Ivo adota uma abordagem personalizada para cada paciente. 
        Ele entende que as necessidades nutrológicas variam de pessoa para pessoa, 
        levando em consideração o tipo de esporte praticado, objetivos individuais, 
        estilo de vida e preferências alimentares. Ele desenvolve planos nutrológicos sob medida, 
        garantindo que seus pacientes atinjam seu máximo potencial.</p>
      </div>
   </section>
    
    <Footer></Footer>
    </>
  );
}

export default Ivo;
