import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';

const NotFound = () => {

  return (
    <>
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>404 - Página Não Encontrada</h1>
      <p>A página que você está procurando não existe.</p>
      <Link to="/">Voltar para a página inicial</Link>
    </div>
    </>
  );
};

export default NotFound;
