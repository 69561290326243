import React from 'react';
import Navbar from '../../components/Navbar/index.js';
import Footer from '../../components/Footer/index.js';
import './style.css';
import Video from '../../Assets/video2.mp4';
import Foto from '../../Assets/Joao.jpeg';
import logoFundo from '../../Assets/logo-nutri.png'


function Joao() {
  
  return (
    <>
    <Navbar />
   <header className='video-nutricionistas'>
        <video src={Video} type="video/mp4" className="video-container" loop autoPlay playsinline muted></video>
        <div className='content-mobile'>
        <img src={Foto} className='foto-mobile'></img> 
        <div className='info-mobile'>
          <h1 className='name-mobile'>Matheus Cavalcanti</h1>
          <p className='job-mobile'>Nutricionista</p>
        </div>
      </div>
   </header>
   <div className='content'>
    <img src={Foto} className='foto'></img> 
    <div className='info'>
      <h1 className='name' style={{fontSize: "40px"}}>Matheus Cavalcanti</h1>
      <p className='job'>Nutricionista</p>
    </div>
   </div>
   <section className='content-nutri'>
    <img src={logoFundo} className='logo-fundo'></img>
    <div content='content-info'>
        <h1 className='title'>Sobre</h1>
        <p className='description'>João Cavalcanti , é um profissional dedicado e alucinado pela área da nutrição. 
        João faz parte do nosso time de nutricionistas aqui na Clínica Bridges, onde desempenha um papel fundamental com a característica de viver o que prega, 
        trás uma pespectiva de ser um nutri que ‘’vive a parada intensivamente’’, visando mudanças de vida, elevando seus resultados e performance ao nível máximo.
        Com formação acadêmica sólida e anos de experiência prática, João acredita que a nutrição não é somente sobre como se alimentar, mas sobre o que uma alimentação
        saudável e rotinas é capaz de impactar na sua vida em todos os âmbitos.
        Na Clínica Bridges, João trabalha de perto com seus pacientes, 
        oferecendo orientação personalizada e planos de alimentação adaptados às necessidades individuais de cada paciente.
        </p>
        {/*<h2 className='crn'>CRN: 11712</h2>*/}
      </div>
   </section>
    
    <Footer></Footer>
    </>
  );
}

export default Joao;
