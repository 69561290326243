import React from "react";
import './style.css';
import Logo from '../../Assets/logofooter.png';
import Logotipo from '../../Assets/logotipofooter.png';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h3>Fortaleza</h3>
          <a href="https://maps.app.goo.gl/FRJUB4jue9yNjPUb9">
            <p>
            Av. Humberto Monte, 2929 - Pici
            Harmony Premium - 4 andar, torre norte, Sala 402 -  Clinica Bridges
            </p>
          </a>
        </div>
        <div className="footer-section">
          <h3>Horário</h3>
          <a href="https://wa.me/5585996444098">
            <p>
            Agendamento através do WhatsApp de segunda-feira a sexta-feira, das 8h às 20h, sábado de 8h às 15h.
            </p>
            <br></br>
          </a>
        </div>
        <div id="redes" className="footer-section">
          <h3>Redes sociais</h3>
          <ul>
            <a href="https://www.instagram.com/clinicabridges/"><li><InstagramIcon></InstagramIcon></li></a>
            <a href="https://wa.me/5585996444098"><li><WhatsAppIcon></WhatsAppIcon></li></a>
            <li><EmailIcon></EmailIcon></li>
          </ul>
        </div>
        <div className="footer-section">
          <img src={Logo} alt="Logo" />
        </div>
      </div>
      <div className="cnpj">
        <h2>CLINICA BRIDGE SAUDE E PERFORMANCE LTDA 51.733.883/0001-80</h2>
      </div>
    </footer>
  );
}
