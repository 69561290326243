import React from 'react';
import './style.css';


function Alert() {

  return (
    <>
    <div className='alert'>
        <h2>Bem-vindo!</h2>
    </div>
    </>
  );
}

export default Alert;
