import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './global.css';
import App from './Pages/App';
import Leo from './Pages/Leo/index';
import Joao from './Pages/Joao/index';
import Lara from './Pages/Lara/index';
import Ivo from './Pages/Ivo/index';
import Jessica from './Pages/Jessica/index';
import NotFound from './components/NotFound';


ReactDOM.render(
  <Router>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/Leo" element={<Leo />} />
      <Route path="/Joao" element={<Joao />} />
      <Route path="/Ivo" element={<Ivo />} />
      <Route path="/Lara" element={<Lara />} />
      <Route path="/Jessica" element={<Jessica />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </Router>,
  document.getElementById('root')
);
