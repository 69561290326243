import React, { useState, useEffect } from 'react';
import './style.css';
import Logo from '../../Assets/logo-bridges.png';
import Menu from '../../Assets/menu-burger (1).png';

export default function Navbar() {

  const [menuAberto, setMenuAberto] = useState(false);
  const [larguraTela, setLarguraTela] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setLarguraTela(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    if (larguraTela >= 1128) {
      setMenuAberto(true); // Defina o menu como aberto por padrão para telas >= 1020px
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [larguraTela]);

  const toggleMenu = () => {
    if (larguraTela < 1020) {
      setMenuAberto(!menuAberto);
    }
  };

  const listaStyles = {
    display: menuAberto ? 'flex' : 'none',
  };

    
    return (
      <>
      <div>
        <div className="Container">
          <ul>
              <li><a href="#inicio">Início</a></li>
              <li><a href="#institucional">Institucional</a></li>
              <li><a href="#especialidades">Especialidades</a></li>
          </ul>
          <a href='/'>
            <img src={Logo} alt="Logo" />
          </a>
          <ul>
              <li><a href="#Nutri">Nutricionistas</a></li>
              <li><a href="#midia">Na mídia</a></li>
              <li><a href="#Exames">Exames</a></li>
          </ul>
        </div>


        <div className='menu'>
        <div className="logo">
          <a href=''>
            <img src={Logo}></img>
          </a>
        </div>
          <div class="hamburger-menu" onClick={toggleMenu}>
            <img src={Menu} ></img>
        </div>
        <ul style={listaStyles}>
          <div>
            <li><a href="#inicio">Início</a></li>
            <li><a href="#institucional">Institucional</a></li>
            <li><a href="#especialidades">Especialidades</a></li>
            <li><a href="#Nutri">Nutricionistas</a></li>
            <li><a href="#midia">Na mídia</a></li>
            <li><a href="#Exames">Exames</a></li>
          </div>
        </ul>
      </div>
      </div>
      </>
    );
  }
  

  