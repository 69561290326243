import React, { useEffect } from 'react';
import './style.css';
import Caliometria from '../../Assets/caliometria.jpg';
import CaliometriaRepouso from '../../Assets/caliometria-repouso.jpg';
import Biopedancia from '../../Assets/biopedancia.jpg';
import Fire from '../../Assets/fire.png';
import Smoke from '../../Assets/fire-smoke.png';
import Bio from '../../Assets/3d.png';


function Exames() {
  
  return (
    <>
    <section id="Exames" className='exames-section'>
        <h1 className='exame-top'>Explore seu potencial e desbloqueie novas oportunidades com nossos exames especializados.</h1>
        <div className='content-exame'>
            <img src={Caliometria} className='exame-img' />
            <div className='exame-box'>
                <img src={Fire} className='icon'/>
                <h2 className='exame-title'>Calorimetria</h2>
                <p className='exame-description'>A calorimetria no contexto da nutrição refere-se 
                à medição do conteúdo calórico dos alimentos e da quantidade de energia liberada 
                durante o seu metabolismo no corpo humano. Essa técnica é essencial para entender 
                como os alimentos contribuem para a ingestão total de energia e como essas calorias
                 são utilizadas no corpo.</p>
            </div>
        </div>
        <div className='content-exame-reverse'>
            <img src={CaliometriaRepouso} className='exame-img' />
            <div className='exame-box'>
                <img src={Smoke} className='icon'/>
                <h2 className='exame-title'>Calorimetria em repouso</h2>
                <p className='exame-description'>A calorimetria em repouso, também
                 conhecida como calorimetria indireta ou calorimetria respiratória, 
                 é uma técnica utilizada em nutrição e fisiologia do exercício para 
                 medir a taxa metabólica basal (TMB) ou o gasto energético em repouso
                (GER). A TMB é a quantidade mínima de energia necessária para manter
                as funções vitais do organismo em repouso, como respiração, circulação
                sanguínea, função cerebral e manutenção da temperatura corporal.</p>
            </div>
        </div>
        <div className='content-exame'>
            <img src={Biopedancia} className='exame-img' />
            <div className='exame-box'>
                <img src={Bio} className='icon'/>
                <h2 className='exame-title'>Biopedância 3D</h2>
                <p className='exame-description'>A bioimpedância 3D é 
                utilizada em uma variedade de contextos, incluindo pesquisa 
                científica, medicina esportiva, nutrição, controle de peso e 
                monitoramento do estado de saúde. Ela fornece informações valiosas 
                sobre a composição corporal, permitindo uma avaliação mais precisa 
                do estado nutricional, planejamento de dietas e programas de exercícios, 
                e acompanhamento do progresso ao longo do tempo.</p>
            </div>
        </div>

    </section>
    </>
  );
}

export default Exames;
